import ActionTypes from '../actions/ActionTypes';
import axios from 'axios';
import * as moment from 'moment';
import * as Utils from '../utils/Utils';
import * as MessagesUtils from '../utils/MessagesUtils';
import _ from 'lodash';
import APIS from 'src/constants/api';

import { toast } from '@d3sw/one-ui-components';

export const resetMessages = () => async (dispatch, getState) => {
  dispatch({
    type: ActionTypes.CLEAR_MESSAGES
  });
};

export const fetchMessages = (pageNumber = 1, filter, perPackageId) => {
  return async (dispatch, getState) => {
    const url = APIS.MESSAGES.GET_MESSAGES;
    const payload = {
      userName: getState().authUser.userName,
      authToken: getState().authUser.authToken,
      filter,
      pageNumber,
      broadcasterType: getState().authUser.reviewerUser ? Object.keys(getState().authUser.broadcasterUsersInfo)[0] : null
    };

    return axios
      .post(url, payload)
      .then(({ data }) => {
        return dispatch(formatMessages(data, perPackageId));
      })
      .catch((err) => {
        console.error(err);
      });
  };
};

const messageParser = ({ messages, authUser, staticData }) => {
  return messages.map((message) => ({
    sender: MessagesUtils.getSender(authUser, staticData, message),
    senderID: message.senderID,
    senderNetwork: MessagesUtils.getSenderNetwork(authUser, staticData, message),
    recipientNetwork: MessagesUtils.getAllRecipientNetwork(message.recipients, staticData, authUser),
    allRecipients: MessagesUtils.getAllRecipient(authUser, staticData, message.recipients),
    date: message.created,
    formattedDate: formatDate(message.created),
    subject: message.subject,
    message: MessagesUtils.stripHTMLTagsfromString(message.body),
    unread: authUser.submitterUser ? (message.recipients.user ? message.recipients.user.slice().shift().unread : true) : message.unread,
    hasAttachment: MessagesUtils.hasAttachment(message),
    attachments: MessagesUtils.getAttachments(message),
    messageID: message.submitterMessageID || message.broadcasterMessageID,
    messagePreview: MessagesUtils.getMessagePreview(message),
    attachmentRequests: message.attachmentRequests,
    broadcasterMessageId: message.broadcasterMessageID,
    broadcasterGroupId: message. broadcasterGroupID,
    submitterMessageId: message.submitterMessageID,
    rootBroadcasterMessageId: message.rootBroadcasterMessageId,
    rootSubmitterMessageId: message.rootSubmitterMessageId,
    status: message.status
  }));
};

export const formatMessages = (data, perPackageId) => {
  return async (dispatch, getState) => {
    try {
      const { authUser, staticData } = getState();
      let currentUserMessages = [];
      let parsedMessages = [];

      if (authUser.submitterUser) {
        currentUserMessages = data.result.submitterUserInboxMessages || [];
      } else if (authUser.reviewerUser) {
        currentUserMessages = data.broadcasterResults || [];
      }
      if (currentUserMessages.length > 0) {
        parsedMessages = messageParser({ messages: currentUserMessages, authUser, staticData });
      }

      if (authUser.reviewerUser) {
        parsedMessages = parsedMessages.sort((a, b) => 2 * moment(a.date).isBefore(moment(b.date)) - 1);
      }

      let parsedData = {
        unreadCount: parsedMessages.filter(({ unread }) => unread).length,
        messageCount: authUser.submitterUser ? data.messageCount : parsedMessages.length,
        pageNumber: data.pageNumber,
        totalPages: data.pages,
        messagesList: parsedMessages
      };

      if (!perPackageId) {
        dispatch({
          type: ActionTypes.MESSAGES_SUCCESS,
          payload: parsedData
        });
      }

      return parsedData;
    } catch (e) {
      return Promise.reject(e);
    }
  };
};

export const markCurrentMessageAsRead = (messagePayload, broadcasterType) => {
  return async (dispatch, getState) => {
    const url = broadcasterType ? APIS.MESSAGES.MARK_MESSAGE_AS_READ_REVIEWER : APIS.MESSAGES.MARK_MESSAGE_AS_READ;

    messagePayload.userName = getState().authUser.userName;
    messagePayload.authToken = getState().authUser.authToken;
    messagePayload.broadcasterType = broadcasterType;
    return axios
      .put(url, messagePayload)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: ActionTypes.MARK_MESSAGE_AS_READ,
            payload: {
              id: response.data.message[getState().authUser.submitterUser ? 'submitterMessageId' : 'submitterMessageID']
            }
          });
          return response;
        }
      })
      .catch(function (error) {
        if (error.status === 401) {
          dispatch({
            type: ActionTypes.SIGN_IN_FAILURE,
            payload: {
              authorized: false,
              authToken: '',
              userName: '',
              hasError: true,
              errorMessage: 'This session has ended for your security, as a new login has occurred with the same account.'
            }
          });
        } else {
          toast.error('Error updating message status ');
        }
      });
  };
};

export const sendNewMessage = (newMessage, uploadedFiles, broadcasterType) => {
  return async (dispatch, getState) => {
    const submitterUser = getState().authUser.submitterUser;
    const url = submitterUser ? APIS.MESSAGES.SEND_NEW_MESSAGE : APIS.MESSAGES.SEND_NEW_MESSAGE_REVIEWER;

    const payload = {
      userName: getState().authUser.userName,
      authToken: getState().authUser.authToken,
      message: newMessage
    };
    if (uploadedFiles) {
      payload.uploadedFileNametoFilePaths = uploadedFiles;
    } else {
      payload.uploadedFileNametoFilePaths = [];
    }

    if (getState().authUser.reviewerUser) {
      payload.broadcasterType = broadcasterType;
    }

    return axios
      .post(url, payload)
      .then(function (response) {
        if (response.status === 200) {
          return response;
        } else if (response.status === 500) {
          toast.error('Error sending message');
        }
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          dispatch({
            type: ActionTypes.SIGN_IN_FAILURE,
            payload: {
              authorized: false,
              authToken: '',
              userName: '',
              hasError: true,
              errorMessage: 'This session has ended for your security, as a new login has occurred with the same account.'
            }
          });
        } else {
          toast.error('Error sending message');
        }
      });
  };
};

export const updateSendMessage = (updatedMessage, broadcasterType) => {
  return async (dispatch, getState) => {
    const url = APIS.MESSAGES.UPDATE_SENT_MESSAGE_STATUS;
    const payload = {
      userName: getState().authUser.userName,
      authToken: getState().authUser.authToken,
      message: updatedMessage
    };

    return axios
      .put(url, payload)
      .then(function (response) {
        if (response.status === 200) {
          return response;
        }
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          dispatch({
            type: ActionTypes.SIGN_IN_FAILURE,
            payload: {
              authorized: false,
              authToken: '',
              userName: '',
              hasError: true,
              errorMessage: 'This session has ended for your security, as a new login has occurred with the same account.'
            }
          });
        }
      });
  };
};

export const updateMessageUnreadCount = (unreadCount) => {
  return async (dispatch, getState) => {
    dispatch({
      type: ActionTypes.UNREAD_MESSAGE_COUNT,
      payload: unreadCount
    });
  };
};

export const uploadInboxFileAttachment = (file) => {
  return async (dispatch, getState) => {
    const url = APIS.SUBMISSION.ATTACHMENT_UPLOAD;

    const formData = new FormData();
    formData.append('mediaFile', file);
    formData.append('userName', getState().authUser.userName);

    for (let pair of formData.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }
    return axios
      .post(url, formData)
      .then(function (response) {
        if (response.status === 200) {
          return response;
        }
        return 'Error File Upload';
      })

      .catch(function (err) {
        if (err.response.status === 401) {
          dispatch({
            type: ActionTypes.SIGN_IN_FAILURE,
            payload: {
              authorized: false,
              authToken: '',
              userName: '',
              hasError: true,
              errorMessage: 'This session has ended for your security, as a new login has occurred with the same account.'
            }
          });
        } else {
          toast.error('File Upload Unsuccessful -- please try again');
        }
      });
  };
};

export const uploadFileAttachment = (file) => {
  return async (dispatch, getState) => {
    const url = APIS.SUBMISSION.ATTACHMENT_UPLOAD;

    const formData = new FormData();
    formData.append('mediaFile', file);
    formData.append('userName', getState().authUser.userName);

    return axios
      .post(url, formData)
      .then(function (response) {
        if (response.status === 200) {
          return response;
        } else {
          return 'Invalid Selection';
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch({
            type: ActionTypes.SIGN_IN_FAILURE,
            payload: {
              authorized: false,
              authToken: '',
              userName: '',
              hasError: true,
              errorMessage: 'This session has ended for your security, as a new login has occurred with the same account.'
            }
          });
        } else {
          toast.error('File Upload Unsuccessful, please try again');
        }
      });
  };
};

export const createNewMessage = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.CREATE_NEW_MESSAGE,
      payload
    });
  };
};

export const setNewMessageVisibility = (visible) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.NEW_MESSAGE_SET_VISIBILITY,
      payload: visible
    });
  };
};

export const clearNewMessage = () => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.CLEAR_NEW_MESSAGE
    });
  };
};

const formatDate = (date) => {
  const today = moment();
  return moment(date).isBefore(moment(today).subtract(3, 'd')) ? moment(date).format('MMM DD YYYY') : moment(date).fromNow();
};
